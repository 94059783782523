@import 'variable';

* {
    box-sizing: border-box;
}

html,body,#root {
    height: 100%;
    color: #eee;
}

.logo-cu {
    border-bottom: solid 3px $secondary-color;
}

.App {
    background-color: #222;
    color: $default-text-color;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    &-header {
        background-color: #282c34;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: white;
    }

    .content {
        margin: 0;
        overflow: auto;
        padding: 0;
        scroll-behavior: smooth;

        &>* {
            margin: 0 auto;
            padding-bottom: 1rem;
        }

        section {
            padding: 1rem;

            & > * {
                max-width: 1333px;
            }

        }
    }
}

nav {
    flex-grow: 1;
    font-size: 1rem;
    right: 1.5em;
    margin-right: auto;
    max-width: 1011px;
    padding-right: .4rem;
    position: relative;
    text-align: right;

    ul {
        display: flex;
        justify-content: flex-end;
        list-style: none;

        li {
            margin: 0 0 0 1rem;
        }
    }

    a.main-action {
        background-color: $base-color;
        border-radius: .4em;
        color: #333;
        padding: .5rem 1rem;
        text-decoration: none;

        &:hover {
            background-color: $base-color;
            color: #555;
            filter: brightness(1.1)
        }
    }
}

section {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 7.5rem);
}

h1 {
    font-size: 2.3rem;
    flex-grow: 1;
    margin: .2em 0;
    margin-left: auto;
    max-width: 400px;
    padding-left: .4em;

    a {
        color: $title-text-color;
        display: flex;
        text-decoration: none;
    }

    img {
        margin-right: .2rem;
        min-width: 1.8rem;
        width: 2.3rem;
    }
}

h2,
h3,
h4,
h5 {
    border-bottom: dotted 2px $base-color;
    color: white;
    margin: 1rem 0 1rem;
    padding-bottom: .3rem; 
}
h5 {
    font-size: 1em;
}

a {
    color: $base-color;
}

.btn {
    border-radius: .5rem;
    padding: .5rem 1rem;
    text-decoration: none;
}
// Form
button {
    border: none;
    border-radius: .4em;
    background-color: $base-color;
    color: #555;
    font-weight: 600;
    padding: .6em 1.2em;

    &:hover {
        box-shadow: 0 0 .5em .1em #999;
        color: #333;
    }

    &.disabled {
        filter: brightness(70%);

        &:hover {
            box-shadow: none;
            color: #555;
        }
    }
}

input,
textarea {
    border: none;
    border-radius: .4em;
    margin-top: .4em;
    padding: .5em;

    &:focus-visible {
        outline: solid .2em $base-color;
    }
}

.empty-list {
    color: #666;
    text-align: center;
    text-transform: uppercase;
}

footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    min-height: 20rem;
    max-width: 1333px;
    padding: 2rem;

    & > div {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        width: 100%;
    }

    .title {
        box-shadow: 0 7px 8px -7px $secondary-color;
        color: #fff;
        margin-bottom: 2rem;
        padding-bottom: .5rem;
        text-align: center;
        text-transform: uppercase;
    }

    a {
        color: $default-text-color;
        display: flex;
        gap: .5rem;
        margin: 0 1rem;
        text-decoration: none;

        &:hover {
            color: #fff;
        }

        span {
            color: $base-color;
        }
    }
}

@media only screen and (min-width: 900px) {
    .App-header {
        display: flex;
        flex-direction: row;
    }

    h1 {
        font-size: 3.1rem;

        img {
            width: 3rem;
        }
    }

    h2 {
        font-size: 2rem;
    }

    section {
        min-height: calc(100vh - 5rem);
    }

    footer {
        & > div {
            max-width: 33%;
        }
    }
}
