.App article.post .linkedin,
.linkedin {
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    margin-right: .4rem;

    &-image {

        &>img {
            margin: 0 .3rem;
            transition: .5s;
            width: 3rem;
            -webkit-mask-image: radial-gradient(circle at 50% 54%, black 65%, rgba(0, 0, 0, 0.0) 50%);

            &:hover {
                transform: scale(1.35);
            }
        }
    }

    .name {
        font-weight: 600;
    }
}
