@import '../variable.scss';

.survey {
    background-color: $base-color;
    color: #fff;
    padding: 1em;

    a {
        color: $secondary-color;
    }

    input,
    textarea {
        width: 100%;
    }

    input[type=checkbox] {
        width: auto;
    }

    div {
        margin-top: .5em;
        text-align: left;
    }

    .errors {
        background-color: #faa;
        border: solid 2px #f88;
        border-radius: .4rem;
        color: #444;
        padding: .5em;
    }

    .errorsList li {
        text-transform: capitalize;
    }

    p {
        margin-top: 0;
    }

    .wizard {
        background-color: rgba(0, 0, 0, .4);
        border-radius: .4rem;
        padding: 1rem;
        width: 99%;
    }
    
    .sended-message {
        flex-grow: 1;
    }

    @media only screen and (min-width: 900px) {
        .wizard {
            margin: 1rem 1rem auto 1rem;
        }
    }
}
