@import '../variable';

section.service {
    background-image: linear-gradient(
            rgba(100, 100, 100, .2), 
            rgba(0, 0, 0, .5), 
            rgba(100,100,100, .2)),
        url('../../public/home4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

div.service {
    background-color: rgba(0, 0, 0, .6);
    border-radius: .4em;
    color: #eee;
    display: flex;
    flex-direction: column;
    margin: 1rem;
    padding: 1em;


    h2 {
        margin-top: 0;
        text-transform: uppercase;
        color: #fff;
    }

    p {
        color: #aaa;
        line-height: 1.5em;
    }

    strong {
        color: #bbb;
        font-size: 1.4em;
        display: block;
    }

    .detail {
        display: none;
    }

    .link {
        color: #aaa;
        cursor: default;
    }

    .btn {
        animation-duration: 4s;
        animation-name: breathe;
        align-self: flex-end;
        background-color: $secondary-color;
        color: #fff;
    }

    @media only screen and (min-width: 900px) {
        .detail {
            display: inline-block;
        }

        .link {
            display: none;
        }

        .App & {
            max-width: 900px;
        }
    }
}

@keyframes breathe {
    60% {
        transform: rotate(0);
    }
    70% {
        transform: rotate(10deg);
    }
    80% {
        transform: rotate(-10deg);
    }
    90% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(0);
    }
}
