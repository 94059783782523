@import '../variable.scss';

.App .content {
    & .blog-body,
    & article,
    & h2 {
        max-width: 85rem;
    }
}

h2 {
    margin-top: 1rem;

    .App &.main-section {
        background-color: #fff;
        color: #333;
        text-transform: uppercase;
    }

    a {
        text-decoration: none;
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }
}

.App .content article {
    background-color: #fff;
    color: #333;
    padding-top: 1px;
}

.App .content .blog-body article {
    h2,h3,h4 {
        color: #333;
        padding: 0 0 .1rem;
    }

}

@media(min-width: 900px) {
    h2 {
        .App .content &.main-section {
            padding: 1rem 6rem;
        }
    }
}
