@import '../variable';

.workflow {
    .box {
        background-color: $secondary-color;
        color: #eee;
        max-width: 20rem;
        padding: 1rem 2rem;
        text-align: center;
        width: 70vw;
    }

    .description {
        padding: 1rem 2rem;
    }

    h2 {
        border-bottom: none;
        margin: 2rem 0 1.3rem;
        text-align: center;
    }

    .nro {
        color: #eee;
        font-size: 6em;
        left: -.6em;
        position: absolute;
        top: -.2em;
    }

    .steps {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-around;
        margin: auto 1rem;
    }

    .workflow-step {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 1em 4em;
        position: relative;

        &:nth-child(odd) {
            transform: rotate(2deg);
        }

        &:nth-child(2n) {
            transform: rotate(-2deg);
            margin-bottom: 3em;
        }

        &:first-child {
            .box {
                background-color: $base-color;
                color: #333;
            }
        }

        &:last-child {
            transform: none;

        }
    }

    @media only screen and (min-width: 900px) {

        .workflow-step {
            flex-wrap: nowrap;
            margin: 0 0 3em 5em;

            .box {
                flex-shrink: 0;
            }

            .nro {
                left: -.7em;
            }

            &:nth-child(odd){
                transform: none;

                .box {
                    transform: rotate(2deg);
                }
            }

            &:nth-child(2n){
                transform: none;

                .box {
                    transform: rotate(-2deg);
                }
            }

            &:last-child {
                .box {
                    transform: none;
                }
            }
        }

        h2 {
            margin-bottom: 2em;
        }

    }
}
