@import '../variable.scss';

.clients {
    background-color: $secondary-color;
    .content {
        flex-grow: 1;
        gap: 1rem;
        padding: 1rem 0;

        .client {
            display: grid;
            gap: 1rem;
            grid-template-areas: 
                "desktop mobile"
                "title title";
            grid-template-columns: 3fr .94fr;
        }
    }
    
    .carousel img {
        border-radius: .4rem;
        width: 100%;
    }

    .title {
        font-size: 1.4rem;
        grid-area: title;
        text-align: center;
    }
}

.carousel {
    border-radius: .4rem;
    overflow: hidden;
    position: relative;
    max-width: 799px;
    width: 100%;

    &.mobile {
        grid-area: mobile;
    }

    &.desktop {
        grid-area: desktop;
    }
    
    &>div {
        white-space: nowrap;
    }
    
    .controls {
        bottom: 2rem;
        left: 0;
        position:absolute;
        right: 0;
        text-align: center;
        
        & span {
           margin: 0 1rem; 
           cursor: pointer;
        }
    }
}
