@import '../../variable.scss';

$icon-size: 20vw;
$max-icon-size: 9rem;

.App .post {
    text-decoration: none;

    article {
        border-bottom: solid 1px #ddd;
        display: grid;
        grid-template-areas: 
            "image title"
            "image tags"
            "image metadata";
        grid-template-columns: $icon-size + 2 auto;

        h2 {
            align-self: end;
            border-bottom: none;
            grid-area: title;
            margin: 0;
        }

        .date-author {
        }

        .tags {
            grid-area: tags;
        }
    }

    &-image {
        color: $base-color;
        font-size: $icon-size;
        grid-area: image;
        margin: 0 1rem 0 0;
        align-self: center;
    }
}

@media(min-width: 900px) {
    .App .post {
        &-image {
            font-size: $max-icon-size;
        }

        article {
            grid-template-columns: $max-icon-size + 2 auto;
            grid-template-rows: 4rem 2rem auto;
            padding-left: 12rem;
            padding-right: 12rem;
        }
    }
}
