.chatbot {
    background-color: #282c34;
    border-radius: .7rem;
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 5vw;
    max-height: 92vh;
    padding: 1rem .7rem 2rem;
    position: fixed;
    right: 5vw;
    width: 90vw;

    .chatHistory {
        background-color: #eee;
        color: #888;
        min-height: 28rem;
        overflow: auto;

        & > div {
            margin: .3rem .5rem;
        }
    }

    h3 {
        border-bottom: none;
        margin: .2rem;
        position: relative;

        button {
            border-radius: .4rem;
            bottom: .3rem; 
            position: absolute;
            right: 0;
        }
    }

    .message{
        background-color: #fff;
        border-radius: .5rem;
        display: inline-block;
        max-width: 80%;
        padding: .5rem;
    }

    .left {
        text-align: left;

        & > div {
            background-color: #dbf;
            color: #555;
        }
    }

    .right {
        text-align: right;
    }

    .ant-spin {
        display: block;
        margin: .5rem auto;
    }

    .error {
        display: flex;

        span {
            color: #faa;
            font-size: 1.8rem;
            margin: .3rem .6rem;
        }
    }

    button {
        border: none;
        border-radius: 0 .5rem .5rem 0; 
    }

    form {
        display: flex;
        margin-top: 1rem;
    }

    input {
        border: none;
        border-radius: .5rem 0 0 .5rem ;
        flex-grow: 1;
        margin-top: 0;
        outline: none;
        padding: 0.5rem;
    }
}

.hide-chatbot-button {
    display: none;
}

@media (min-width: 768px) {
    .chatbot {
        left: auto;
        right: 1vw;
        width: 30rem;
    }
}
