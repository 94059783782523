.App .content {
    & article,
    & h2 {
        padding: 2rem;
    }

    article.post {

    }
}

.App .content article.post {
    h2:first-child {
        //margin-top: 1rem;
        margin: 0;
    }

    h2,h3,h4 {
        margin-top: 3rem;
        padding: 0 0 .1rem;
    }

    img {
        display: block;
        margin: auto;
        max-width: 60rem;
        width: 85vw;
    }

    p {
        line-height: 1.5rem;
    }
}

pre {
    max-height: 20rem;

    code[class*=language-] {
        font-size: .8rem;
    }
}

@media(min-width: 900px) {
    .App .content article.post {
        padding: 1px 12rem;

        h2:first-child {
            padding-top: 3rem;
        }

        img {
            width: 70vw;
        }
    }
}
