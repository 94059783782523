.error-page {
    background-color: #222;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1rem;

    h1 {
        flex-grow: 0;
        margin: 0;
    }
}
